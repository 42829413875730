module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Danton Nguyen","short_name":"Danton Nguyen","description":"Danton Nguyen's Personal Website","start_url":"/","background_color":"#000000","theme_color":"#24f8e5","display":"minimal-ui","icon":"src/images/favicon.png","lang":"en","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"84c84221fae3accc759d6260ea1e6f51"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
